import {ReactComponent as Logo} from "./logo-green.svg";
import './App.css';
import React, {useState, useEffect} from "react";
import {PostToCupload, PostToResend} from "./API";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query"

export const queryClient = new QueryClient()

function App() {
  return <QueryClientProvider client={queryClient}>
    <Content />
  </QueryClientProvider>
}

function Content() {
  const [checked, setChecked] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [fieldsChecked, setFieldsChecked] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [nickname, setNickname] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailed, setShowFailed] = useState(false)

  useEffect(() => {
    if (checked && phoneNumber.length > 0 && nickname.length > 0) {
      setFieldsChecked(true)
    } else {
      setFieldsChecked(false)
    }
  },[checked, phoneNumber, nickname])

  const [cuploadBody, setCuploadBody] = useState({})

  useEffect(() => {
    if (phoneNumber.length > 0 && nickname.length > 0) {
      setCuploadBody({
        "nickname": nickname,
        "phonenumber": phoneNumber
      })
    }
  },[phoneNumber, nickname])

  const cuploadPost = PostToCupload()
  const resendPost = PostToResend()

  const clearFields = () => {
    setPhoneNumber("")
    setNickname("")
    setChecked("")
  }

  const startSurvey = () => {
    cuploadPost.mutate(cuploadBody, {
      onError: (error) => {
        console.log("Cupload post error: ", error)
        setShowFailed(true)
        clearFields()
      },
      onSuccess: (data) => {
        console.log("Cupload post success: ", data)
        const cuploadData = [data.data.id]
        resendPost.mutate(cuploadData, {
          onError: (error) => {
            console.log("Resend error: ", error)
            setShowFailed(true)
            clearFields()
          },
          onSuccess: (data) => {
            console.log("Resend post success: ", data)
            setShowSuccess(true)
            clearFields()
          }
        })
      }
    })
  }

  return <>
    <div className="App">
      <header>
        <Logo />
        <h1>
          Testaa Annie-tukibottia!
        </h1>
        <p>
          Täytä tietosi alle, niin Annie lähettää sinulle
          tekstiviestin.
        </p>
        <p>
          Jos ilmoitat Annielle tuen tarpeen,
          se on nähtävissä Annien demoympäristössä.
        </p>
      </header>
      <label htmlFor={"nimimerkki"}>Nimimerkki *</label><br/>
      <input id={"nimimerkki"} type={"text"} placeholder={"Syötä tähän"} value={nickname}
             onChange={e => {
               setNickname(e.target.value)
             }}/><br/>
      <label htmlFor={"puhelinnumero"}>Puhelinnumero *</label><br/>
      <input id={"puhelinnumero"} type={"tel"} placeholder={"0401234567"} value={phoneNumber}
             onChange={e => {
               setPhoneNumber(e.target.value)
             }}/><br/>
      <div className={"flex"}>
        <input id={"checkbox"} type={"checkbox"} checked={checked} onChange={()=>setChecked(!checked)}/>
        <label htmlFor={"checkbox"}>Vakuutan että puhelinnumero on omani ja hyväksyn tietojeni <span
            onClick={() => setShowTerms(true)}>tietosuojaselosteen</span> mukaisen käytön.</label><br/>
      </div>
      {!fieldsChecked ?
          <button disabled={true} onClick={()=>alert("Send")}>Käynnistä</button> :
          <button onClick={()=>startSurvey()}>Käynnistä</button>
      }

    </div>
    {showTerms &&
    <div className={"popup-overlay"} onClick={()=>setShowTerms(false)}>
      <div className={"popup"}>
        <h1>Tietosuoja&shy;lauseke</h1>
        <button onClick={()=>setShowTerms(false)}>Sulje</button>
        <Tietosuoja />
        <button onClick={()=>setShowTerms(false)}>Sulje</button>
      </div>
    </div>
    }
    {showSuccess &&
    <div className={"popup-overlay"} onClick={()=>setShowSuccess(false)}>
      <div className={"popup"}>
        <h1>Lähetys onnistui!</h1>
        <p>Saat viestin puhelimeesi hetken kuluttua.</p>
        <button onClick={()=>setShowSuccess(false)}>Selvä</button>
      </div>
    </div>
    }
    {showFailed &&
    <div className={"popup-overlay"} onClick={()=>setShowFailed(false)}>
      <div className={"popup"}>
        <h1>Lähetys epäonnistui</h1>
        <p>Käänny Annien henkilökunnan puoleen.</p>
        <button onClick={()=>setShowFailed(false)}>Selvä</button>
      </div>
    </div>
    }
  </>
}

function Tietosuoja() {
  return <div><p>Annie Advisor Ltd (jäljempänä ”Yritys”) on sitoutunut
    noudattamaan hyviä tietosuojakäytäntöjä. Tämän asiakirjan tarkoituksena on tiedottaa tämän verkkosivuston
    käyttäjälle (jäljempänä rekisteröity) henkilötietojen käsittelyyn liittyvistä periaatteista ja käytännöistä.
    Henkilötiedoilla tarkoitetaan kaikkia tietoja, joiden perusteella henkilö voidaan tunnistaa.</p>
    <p>Päivitetty viimeksi: 7.10.2021</p>
    <p>&nbsp;</p>
    <p>Yrityksen yhteystiedot:</p>
    <p>&nbsp;</p>
    <p>Annie Advisor Oy</p>
    <p>Osoite: Malminkatu 36 A 05, 00100 Helsinki</p>
    <p>Puhelin: +358 40847 2763</p>
    <p>Sähköposti: annie@annieadvisor.com</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol>
      <li>Rekisterinpitäjä ja henkilötietojen käsittelijät</li>
    </ol>
    <p>Rekisterinpitäjä on osapuoli, joka on vastuussa henkilötietojen käsittelystä. Käsittelijä puolestaan on
      osapuoli, joka käsittelee henkilötietoja rekisterinpitäjän puolesta rekisterinpitäjän ja henkilötietojen
      käsittelijän välisen sopimuksen perusteella.</p>
    <p>&nbsp;</p>
    <p>Tämä tietosuojakäytäntö kattaa verkkosivustot, joilla yritys on rekisterinpitäjä ja joiden kautta yritys
      vastaanottaa ja käsittelee henkilötietoja sekä vastaanotettujen tietojen käsittelyä, eli seuraavat:</p>
    <p>annieadvisor.com -sivusto</p>
    <p>Annie Advisor Ltd asiakastiedot</p>
    <p>Henkilötietoja ei luovuteta kolmansille osapuolille. Yhtiö ei käytä ulkoisia tietojenkäsittelijöitä. Tietoja ei
      siirretä, käsitellä tai tallenneta EU/ETA -alueen ulkopuolelle.</p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>Henkilötietojen luokat ja käsittelyn oikeusperusta</li>
    </ol>
    <p>Henkilötietoja käsitellään EU: n yleisen tietosuoja -asetuksen (jäljempänä GDPR) ja sovellettavan kansallisen
      tietosuojalain mukaisesti ja niiden mukaisesti. Henkilötietoja käsitellään vain alla mainittuihin tarkoituksiin,
      ja tällaiselle käsittelylle on aina oikeusperusta, joka perustuu edellä mainittuun tietosuojalainsäädäntöön.</p>
    <p>&nbsp;</p>
    <p>Seuraavia henkilötietojen ryhmiä käsitellään Yhtiön verkkosivuston ja palveluiden tarjoamisen yhteydessä:</p>
    <p>Käyttäjän laitteen malli ja selaimen ohjelmistoversio</p>
    <p>Käyttäjän sijainti</p>
    <p>Käyttäjän yhteystiedot (jos hän täyttää niitä lomakkeeseen osana sivun käyttöä)</p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>Tietoturva</li>
    </ol>
    <p>Yhtiö suojaa Yhtiön verkkosivustoihin tai palveluihin liittyviä henkilökohtaisia tietoja kohtuullisilla,
      asianmukaisilla suojatoimenpiteillä estääkseen tahattoman tietojen vuotamisen, henkilötietojen luvattoman
      käsittelyn sekä henkilökohtaisten tietojen vahingossa tapahtuvan tai luvattoman tuhoamisen, käytön, muuttamisen
      tai paljastamisen. Yhtiö on toteuttanut asianmukaiset tekniset ja organisatoriset toimenpiteet henkilötietojen
      suojaamiseksi. Edellä mainituista toimenpiteistä, kuten yhtiön henkilöstön ja käsittelijöiden pääsyn
      rajoittamisesta henkilötietoihin sekä henkilötietojen tallentamisesta ja käsittelystä salatussa muodossa, on
      päätetty ottaen huomioon mahdollisten tietovuotojen haitallisuus ja todennäköisyys, käsiteltävien henkilötietojen
      arkaluonteisuus, säilytysolosuhteet ja tietotekniikan kehitys.</p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>Rekisteröidyn oikeudet</li>
    </ol>
    <p>GDPR: n mukaan rekisteröidyllä on seuraavat oikeudet henkilötietoihin, kuten GDPR: n 15-21 artiklassa selitetään
      tarkemmin:</p>
    <p>Oikeus saada pääsy: rekisteröidyllä on oikeus pyytää vahvistusta henkilötietojensa käsittelystä Yhtiön
      verkkosivuston tai palveluiden yhteydessä ja saada pääsy tietoihinsa</p>
    <p>Oikeus oikaisuun: rekisteröidyllä on oikeus pyytää rekisterinpitäjää korjaamaan virheelliset tai puutteelliset
      henkilötietonsa, jotka on käsitelty yrityksen verkkosivuston tai palveluiden yhteydessä</p>
    <p>Oikeus tulla unohdetuksi: rekisteröidyllä on oikeus pyytää häntä koskevien henkilötietojen poistamista, jos niitä
      ei enää tarvita siihen tarkoitukseen, jota varten ne on kerätty tai käsitelty, jos rekisteröity vastustaa
      käsittelyä eikä ole pakottavia oikeudelliset perusteet käsittelylle, jos henkilötietoja käsitellään
      lainvastaisesti tai jos henkilötietoja poistetaan lakisääteisen velvoitteen noudattamiseksi</p>
    <p>Oikeus rajoittaa käsittelyä: Rekisteröidyllä on oikeus vaatia henkilötietojen käsittelyn rajoittamista, jos
      henkilötietojen paikkansapitävyys kiistetään, jos käsittely on laitonta tai rekisterinpitäjä ei enää tarvitse
      tällaisia tietoja, mutta rekisteröity on perustellut vastustaa poistamista tai onko käsittelylle
      oikeusperusta</p>
    <p>Oikeus vastustaa: rekisteröidyllä on oikeus vastustaa henkilötietojensa käsittelyä siltä osin kuin tietoja
      käsitellään yrityksen verkkosivuston tai palveluiden yhteydessä GDPR: n 6 artiklan 1 kohdan f alakohdan
      mukaisesti, jolloin rekisterinpitäjän on todistaa, että käsittelylle on pakottava oikeusperusta.</p>
    <p>Oikeus siirtää: rekisteröidyllä on oikeus saada häntä koskevia henkilötietoja ja oikeus saada edellä mainitut
      tiedot toiselle rekisterinpitäjälle siltä osin kuin käsittely perustuu GDPR: n 6 artiklan 1 kohdan b
      alakohtaan</p>
    <p>Lisäksi rekisteröidyllä on aina oikeus valittaa toimivaltaiselle tietosuojaviranomaiselle henkilötietojensa
      käsittelystä yhtiön verkkosivuston tai palveluiden yhteydessä. Suomen toimivaltainen tietosuojaviranomainen on
      tietosuojavaltuutettu: www.tietosuoja.fi.</p>
    <p>&nbsp;</p>
    <p>5. Yhteystiedot henkilötietoihin liittyvissä asioissa</p>
    <p>Yhtiö pyrkii varmistamaan, että sen käsittelemät henkilötiedot ovat asianmukaisesti ajantasaisia, tarkkoja ja
      täydellisiä. Jos rekisteröity haluaa ilmoittaa virheellisiä tietoja, pyytää häntä koskevien henkilötietojen
      poistamista tai muuten esittää kysymyksiä, kommentteja tai muita näihin tietoturvakäytäntöihin liittyviä asioita
      tai jos rekisteröity haluaa valittaa henkilötietojen käsittelystä Yhtiön tai sen asiakkaiden, he voivat ottaa
      yhteyttä yritykseen osoitteessa: annie@annieadvisor.com. Jos yritys käsittelee henkilötietoja asiakkaidensa
      puolesta, rekisteröity voi ottaa yhteyttä asiakkaan (eli rekisterinpitäjän) antamiin yhteystietoihin.</p>
    <p>Yhteydenoton yhteydessä rekisteröityä voidaan pyytää toimittamaan henkilöllisyystodistus tietosuojasyistä.</p>
    <p>6. Muutokset tietosuojalausekkeeseen</p>
    <p>Tähän tietosuojalausekkeeseen voidaan tehdä aika ajoin muutoksia lataamalla päivitetty versio asiakirjasta Yhtiön
      verkkosivustolle, minkä jälkeen päivitetty versio tulee voimaan. Jos tietosuojalausekkeeseen tehdään olennaisia
      muutoksia, Yhtiö pyrkii myös tiedottamaan niistä muilla tavoilla, kuten sähköpostilla, ilmoituksella Yhtiön
      ylläpitämässä palvelussa tai ilmoittamalla verkkosivustolle tai sosiaalisen median sivuille kohtuullisen aikaa
      ennen kuin muutokset tulevat voimaan.</p>
  </div>
}

export default App;
